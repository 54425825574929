import React, { useEffect } from "react";
import {
  GETTING_STARTED,
  INDEX_ROUTE,
  TEST_INTRO_ROUTE,
  TEST_ROUTE_QUESTION,
  TEST_ROUTE_REPORT,
  TEACHER_DASHBOARD,
  ADMIN_DASHBOARD,
} from "./constants";
import { Route, Routes, useNavigate } from "react-router-dom";
import TestIntro from "Pages/TestIntro";
import Dashboard from "Pages/Dashboard";
import Test from "Pages/Test";
import LandingPage from "Pages/LandingPage";
import TestReport from "Pages/TestReport";
import { useUserContext } from "Authenticator";
import Teacher from "Pages/Admin/Teacher";
import Admin from "Pages/Admin/Admin";
import LogRocket from "logrocket";

const PrivateRoutes: React.FC = () => {
  const userContext = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.user) {
      console.log(userContext.user.UserID)
      LogRocket.identify(userContext.user.UserID, {
        email: userContext.user.Email,
      });
    }

    if (
      userContext?.user?.Role === "teacher" &&
      !window.location.pathname.includes("report")
    ) {
      navigate(TEACHER_DASHBOARD);
    }

    if (userContext?.user?.Role === "admin") {
      navigate(ADMIN_DASHBOARD);
    }
  }, [navigate, userContext?.user?.Role]);

  if (userContext?.user?.Role === "admin") {
    return (
      <Routes>
        <Route path={ADMIN_DASHBOARD} element={<Admin />} />
      </Routes>
    );
  }

  if (userContext?.user?.Role === "teacher") {
    return (
      <Routes>
        <Route path={TEST_ROUTE_REPORT} element={<TestReport />} />
        <Route path={TEACHER_DASHBOARD} element={<Teacher />} />
      </Routes>
    );
  }

  if (userContext?.user?.Role === "student") {
    return (
      <Routes>
        <Route path={INDEX_ROUTE} element={<Dashboard />} />
        <Route path={TEST_INTRO_ROUTE} element={<TestIntro />} />
        <Route path={TEST_ROUTE_QUESTION} element={<Test />} />
        <Route path={TEST_ROUTE_QUESTION} element={<Test />} />
        <Route path={TEST_ROUTE_REPORT} element={<TestReport />} />
      </Routes>
    );
  }

  return null;
};

export default PrivateRoutes;

export const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={GETTING_STARTED} element={<LandingPage />} />
    </Routes>
  );
};
