import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const LeftSide = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
  background: #a6a1a1;
  background: white;
  width: 200px;
`;

export const Tile = styled.div<{ active?: boolean }>`
  padding: 20px 10px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  ${({ active, theme }) =>
    active &&
    `

  background: ${theme.colors.primary1};

  `}

  p {
    margin-left: 5px;
  }
`;

export const RightSide = styled.div`
  width: 100%;
`;

export const SchoolBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 24px 20.5px 24px;
  background: ${({ theme }) => theme.colors.primary2};
`;

export const UpdateStudentContainer = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
`;

export const SectionContainer = styled.div`
  margin: auto;
  border-radius: 10px;
  max-width: 1080px;
  margin-top: 24px;
  background: ${({ theme }) => theme.colors.secondary1};
  padding: 48px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    svg {
      cursor: pointer;
    }
  }

  thead tr {
    background: ${({ theme }) => theme.colors.primary1};
    color: #ffffff;
    text-align: left;
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  tbody tr:last-of-type {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
  }
`;

export const ClassHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
  margin-bottom: 24px;
  padding-bottom: 12px;
`;

export const FormDivider = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  button {
    background: ${({ theme }) => theme.colors.primary1};
  }
`;

/// school stuff
export const StepContainer = styled.div`
  margin-top: 80px;
`;

export const InputContainer = styled.div<{ usernameOnly?: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ usernameOnly }) =>
    usernameOnly &&
    `
    justify-content: flex-end;
    div:last-of-type {
      margin-left: 40px;
    }
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  cursor: pointer;

  span {
    background: ${({ theme }) => theme.colors.primary1};
    border-radius: 50%;
    padding: 5px 7px;
  }
`;

// student stuff
export const SectionContainerStudents = styled.div`
  margin: auto;
  border-radius: 10px;
  max-width: 1080px;
  margin-top: 24px;
  background: ${({ theme }) => theme.colors.secondary1};
  padding: 48px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  thead tr {
    background: ${({ theme }) => theme.colors.primary1};
    color: #ffffff;
    text-align: left;
  }

  th,
  td {
    padding: 6px 15px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  tbody tr:last-of-type {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
  }
`;

export const StudentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
`;
//  teacher stuff
export const SectionContainerTeachers = styled.div`
  margin: auto;
  border-radius: 10px;
  max-width: 1080px;
  margin-top: 24px;
  background: ${({ theme }) => theme.colors.secondary1};
  padding: 48px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  thead tr {
    background: ${({ theme }) => theme.colors.primary1};
    color: #ffffff;
    text-align: left;
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  tbody tr:last-of-type {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
  }
`;

export const TeacherHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
