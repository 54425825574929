import React, { useEffect, useState } from "react";
import { School, Teacher } from "./types";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  ColumnDef
} from "@tanstack/react-table";
import {
  IconContainer,
  InputContainer,
  SectionContainerTeachers,
  StepContainer,
  TeacherHeader,
  UpdateStudentContainer
} from "./styles";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { NewStudent as NewTeacher } from "./SchoolOnboard/types";
import { FiUserMinus } from "react-icons/fi";
import { LuUserPlus2 } from "react-icons/lu";
import { Text } from "components/Text";
import { useUserContext } from "Authenticator";
import { postWithToken, updateWithToken } from "hooks/use-fetch-data";
import { Modal } from "components/Modal";
import { GenericTable, ToggleSwitch } from "components";
import { fuzzySort } from "components";

const DEFAULT_USER: Teacher = {
  UserID: 0,
  Email: "",
  FirstName: "",
  LastName: "",
  Role: ""
};

export const Teachers: React.FC<{
  teachers?: Teacher[];
  school?: School;
  refreshDashboard: (schoolSlug?: School) => Promise<void>;
}> = ({ teachers = [], school, refreshDashboard }) => {
  const userCtx = useUserContext();
  const [data, setData] = React.useState(() => [...teachers]);
  const [createNewTeacher, setCreateNewTeacher] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher>(DEFAULT_USER);

  useEffect(() => {
    if (teachers.length !== 0) {
      setData(teachers);
    }
  }, [teachers]);

  const [formData, setFormData] = useState<{
    teachers: NewTeacher[];
  }>({
    teachers: [{ 1: { FirstName: "", LastName: "", Email: "" } }]
  });

  const editUser = (user: Teacher) => {
    setModal(true);
    setSelectedTeacher(user);
  };

  const dataColumnsTeachers: ColumnDef<any, any>[] = [
    {
      accessorFn: row => row.Email, // Accessor function for the title
      id: "Email",
      header: "Email", // Header for the column
      cell: info => info.getValue(), // Cell renderer
      sortingFn: fuzzySort // Sorting function
    },
    {
      accessorFn: row => `${row.FirstName} ${row.LastName}`, // Accessor function for the title
      id: "Name",
      header: "Name", // Header for the column
      cell: info => info.getValue(), // Cell renderer
      sortingFn: fuzzySort // Sorting function
    },
    {
      header: "Edit",
      cell: cell => (
        <button onClick={() => editUser(cell.row.original)}>Edit</button>
      )
    }
  ];

  const teacherChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    index: number
  ) => {
    const updateTeachers = formData.teachers.map(teacher => {
      const teacherIndex = Number(Object.keys(teacher)[0]);

      if (teacherIndex === index) {
        return {
          [index]: {
            ...teacher[index],
            [field]: e.target.value
          }
        };
      }

      return teacher;
    });

    setFormData({
      ...formData,
      teachers: updateTeachers
    });
  };

  const minusTeacher = () => {
    const allButLast = formData.teachers.slice(0, -1);

    setFormData({
      ...formData,
      teachers: [...allButLast]
    });
  };

  const addAnotherTeacher = () => {
    const next =
      Number(Object.keys(formData.teachers[formData.teachers.length - 1])[0]) +
      1;

    setFormData({
      ...formData,
      teachers: [
        ...formData.teachers,
        { [next]: { FirstName: "", LastName: "", Email: "" } }
      ]
    });
  };

  const saveTeachers = async () => {
    const teachers = formData.teachers.map(s => {
      const key = Number(Object.keys(s)[0]);
      return s[key];
    });

    const data = {
      users: teachers,
      schoolID: school?.SchoolID,
      role: "teacher"
    };
    const res = await postWithToken(
      `/api/v1/auth/admin/dashboard/save-users-to-school`,
      userCtx.token,
      {
        ...data
      }
    );

    if (res.status === 201) {
      refreshDashboard(school);
      setCreateNewTeacher(false);
    }
  };

  const updateTeacher = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedTeacher(prevTeacher => ({
      ...prevTeacher,
      [name]: value
    }));
  };

  const submitUpdateTeacher = async () => {
    const res = await updateWithToken(
      `/api/v1/auth/admin/update-user`,
      userCtx.token,
      selectedTeacher
    );

    if (res.status === 201) {
      refreshDashboard(school);
      setModal(false);
      setSelectedTeacher(DEFAULT_USER);
    }
  };

  return (
    <>
      <Modal open={modal} onClose={() => setModal(false)} small height={"20%"}>
        <Text size={32} center>
          Update Teacher
        </Text>
        <UpdateStudentContainer>
          <Input
            width={250}
            name="FirstName"
            value={selectedTeacher.FirstName}
            onChange={e => updateTeacher(e)}
          />
          <Input
            width={250}
            name="LastName"
            value={selectedTeacher.LastName}
            onChange={e => updateTeacher(e)}
          />
          <Input
            width={250}
            name="Email"
            value={selectedTeacher.Email}
            onChange={e => updateTeacher(e)}
          />
        </UpdateStudentContainer>
        <Button
          text="Update Teacher"
          onClick={() => submitUpdateTeacher()}
          floatRight
        />
      </Modal>
      <SectionContainerTeachers>
        {createNewTeacher && (
          <>
            <TeacherHeader>
              <Text size={24}>Create teachers for {school?.Name}</Text>
              <Button
                text="View teachers"
                onClick={() => setCreateNewTeacher(false)}
              />
            </TeacherHeader>
            <StepContainer>
              {formData.teachers.map(teacher => {
                const key = Number(Object.keys(teacher)[0]);
                return (
                  <InputContainer key={key}>
                    <Input
                      width={250}
                      placeholder="First Name..."
                      value={teacher[key].FirstName}
                      onChange={e => teacherChange(e, "FirstName", key)}
                    />
                    <Input
                      width={250}
                      placeholder="Last Name..."
                      value={teacher[key].LastName}
                      onChange={e => teacherChange(e, "LastName", key)}
                    />
                    <Input
                      width={300}
                      placeholder="Email..."
                      value={teacher[key].Email}
                      onChange={e => teacherChange(e, "Email", key)}
                    />
                  </InputContainer>
                );
              })}
              <IconContainer>
                <span>
                  <FiUserMinus size={30} onClick={minusTeacher} />
                </span>
                <span onClick={addAnotherTeacher}>
                  <LuUserPlus2 size={30} />
                </span>
              </IconContainer>
              <Button
                text="Create teachers"
                onClick={saveTeachers}
                floatRight
                mt={44}
              />
            </StepContainer>
          </>
        )}
        {!createNewTeacher && (
          <>
            <TeacherHeader>
              <Text size={24} mb={12}>All Teachers</Text>
              {school?.Slug !== "all" && 
              <Button
                text="Create New Teacher"
                onClick={() => setCreateNewTeacher(true)}
              />
              }
            </TeacherHeader>
            <GenericTable
              data={teachers}
              columns={dataColumnsTeachers}
              idKey="UserID"
              withSearch
              pageSize={20}
            />
          </>
        )}
      </SectionContainerTeachers>
    </>
  );
};
