import React, { useEffect, useState } from "react";
import { School, Student } from "./types";
import { ColumnDef } from "@tanstack/react-table";
import {
  IconContainer,
  InputContainer,
  SectionContainerStudents,
  StepContainer,
  StudentHeader,
  UpdateStudentContainer,
} from "./styles";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { NewStudent } from "./SchoolOnboard/types";
import { FiUserMinus } from "react-icons/fi";
import { LuUserPlus2 } from "react-icons/lu";
import { Text } from "components/Text";
import { useUserContext } from "Authenticator";
import { postWithToken, updateWithToken } from "hooks/use-fetch-data";
import { Modal } from "components/Modal";
import toast from "react-hot-toast";
import { DivComponent, GenericTable, ToggleSwitch } from "components";
import { fuzzySort } from "components";
import { IoMdCopy } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";
import { BsPersonPlus } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { GrDocumentCsv } from "react-icons/gr";

export const DEFAULT_USER: Student = {
  UserID: 0,
  Email: "",
  FirstName: "",
  LastName: "",
  Role: "",
  Password: "",
};

const PasswordInput = ({ row }: any) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(row.original.Password);

    toast.success("Copied to clipboard", {
      position: "bottom-left",
      icon: <FaRegCheckCircle size={18} color="#154471" />,
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p id="link-text" style={{ display: "inline", marginRight: "10px" }}>
        ********
      </p>
      <div onClick={copyToClipboard} style={{ cursor: "pointer" }}>
        <IoMdCopy size={20} />
      </div>
    </div>
  );
};

export const Students: React.FC<{
  students?: Student[];
  school?: School;
  refreshDashboard: (schoolSlug?: School) => Promise<void>;
}> = ({ students = [], school, refreshDashboard }) => {
  const userCtx = useUserContext();
  const [data, setData] = React.useState(() => [...students]);
  const [createNewStudent, setCreateNewStudent] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<Student>(DEFAULT_USER);
  const [usernameOnly, setUsernameOnly] = React.useState(false);

  useEffect(() => {
    if (students.length !== 0) {
      setData(students);
    }
  }, [students]);

  const [formData, setFormData] = useState<{
    students: NewStudent[];
  }>({
    students: [{ 1: { FirstName: "", LastName: "", Email: "" } }],
  });

  const dataColumnsStudents: ColumnDef<any, any>[] = [
    {
      accessorFn: (row) => row.Email, // Accessor function for the title
      id: "Email",
      header: "Email", // Header for the column
      cell: (info) => info.getValue(), // Cell renderer
      sortingFn: fuzzySort, // Sorting function
    },
    {
      accessorFn: (row) => `${row.FirstName} ${row.LastName}`, // Accessor function for the title
      id: "Name",
      header: "Name", // Header for the column
      cell: (info) => info.getValue(), // Cell renderer
      sortingFn: fuzzySort, // Sorting function
    },
    {
      accessorFn: (row) => `${row.Username || "N/A"}`, // Accessor function for the title
      id: "Username",
      header: "Username", // Header for the column
      cell: (info) => info.getValue(), // Cell renderer
      sortingFn: fuzzySort, // Sorting function
    },
    {
      header: "Password",
      cell: (cell) => <PasswordInput {...cell} />,
    },
    {
      header: "Edit",
      cell: (cell) => (
        <button onClick={() => editUser(cell.row.original)}>Edit</button>
      ),
    },
  ];

  const editUser = (user: Student) => {
    setModal(true);
    setSelectedStudent(user);
  };

  const studentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    index: number
  ) => {
    const updateStudents = formData.students.map((student) => {
      const studentIndex = Number(Object.keys(student)[0]);

      if (studentIndex === index) {
        return {
          [index]: {
            ...student[index],
            [field]: e.target.value,
          },
        };
      }

      return student;
    });

    setFormData({
      ...formData,
      students: updateStudents,
    });
  };

  const minusStudent = () => {
    const allButLast = formData.students.slice(0, -1);

    setFormData({
      ...formData,
      students: [...allButLast],
    });
  };

  const addAnotherStudent = () => {
    const next =
      Number(Object.keys(formData.students[formData.students.length - 1])[0]) +
      1;

    setFormData({
      ...formData,
      students: [
        ...formData.students,
        { [next]: { FirstName: "", LastName: "", Email: "" } },
      ],
    });
  };

  const validEmails = (students: NewStudent[number][], noEmails: boolean) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    for (const student of students) {
      if (noEmails) {
        if (!student.FirstName || !student.LastName) {
          alert("First and last names are required");
          return false;
        }
      } else {
        if (!student.Email) {
          alert("Emails are required");
          return false;
        }
        if (!emailRegex.test(student.Email)) {
          alert(`Invalid email: ${student.Email}`);
          return false;
        }
      }
    }

    return true;
  };

  const saveStudents = async () => {
    try {
      const students = formData.students.map((s) => {
        const key = Number(Object.keys(s)[0]);
        return s[key];
      });

      if (!validEmails(students, usernameOnly)) {
        return;
      }

      const data = {
        users: students,
        schoolID: school?.SchoolID,
        role: "student",
        usernameOnly: usernameOnly,
      };

      const res = await postWithToken(
        `/api/v1/auth/admin/dashboard/save-users-to-school`,
        userCtx.token,
        {
          ...data,
        }
      );

      if (res.status === 201) {
        refreshDashboard(school);
        setCreateNewStudent(false);
      }
    } catch (error) {
      const axiosError = error as any;
      toast.error(axiosError?.response?.data?.error, {
        position: "bottom-center",
      });
    }
  };

  const downloadCSV = async () => {
    try {
      const res = await postWithToken(
        `/api/v1/auth/create-csv`,
        userCtx.token,
        {
          allSchoolUsers: school?.Slug,
        }
      );

      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // Create a temporary anchor element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "users.csv"; // Desired file name
        document.body.appendChild(a);
        a.click(); // Trigger download
        document.body.removeChild(a); // Clean up after download

        // Revoke the URL to free up resources
        window.URL.revokeObjectURL(url);
      }
      // Create a URL for the Blob
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const updateStudent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };

  const submitUpdateStudent = async () => {
    const res = await updateWithToken(
      `/api/v1/auth/admin/update-user`,
      userCtx.token,
      selectedStudent
    );

    if (res.status === 201) {
      refreshDashboard(school);
      setModal(false);
      setSelectedStudent(DEFAULT_USER);
    }
  };

  return (
    <>
      <Modal open={modal} onClose={() => setModal(false)} small height={"20%"}>
        <Text size={32} center>
          Update Student
        </Text>
        <UpdateStudentContainer>
          <Input
            width={250}
            name="FirstName"
            value={selectedStudent.FirstName}
            onChange={(e) => updateStudent(e)}
          />
          <Input
            width={250}
            name="LastName"
            value={selectedStudent.LastName}
            onChange={(e) => updateStudent(e)}
          />
          <Input
            width={250}
            name="Email"
            value={selectedStudent.Email}
            onChange={(e) => updateStudent(e)}
          />
        </UpdateStudentContainer>
        <Button
          text="Update Student"
          onClick={() => submitUpdateStudent()}
          floatRight
        />
      </Modal>
      <SectionContainerStudents>
        {createNewStudent && (
          <>
            <StudentHeader>
              <Text size={24}>Create students for {school?.Name}</Text>
              <Button
                text="View Students"
                onClick={() => setCreateNewStudent(false)}
              />
            </StudentHeader>
            <ToggleSwitch
              checked={usernameOnly}
              onChange={() => setUsernameOnly(!usernameOnly)}
              withText="Enable Username Only Login"
            />
            <StepContainer>
              {formData.students.map((student) => {
                const key = Number(Object.keys(student)[0]);
                return (
                  <InputContainer key={key} usernameOnly={usernameOnly}>
                    <Input
                      width={320}
                      placeholder="First Name..."
                      value={student[key].FirstName}
                      onChange={(e) => studentChange(e, "FirstName", key)}
                    />
                    <Input
                      width={320}
                      placeholder="Last Name..."
                      value={student[key].LastName}
                      onChange={(e) => studentChange(e, "LastName", key)}
                    />
                    {!usernameOnly && (
                      <Input
                        width={400}
                        placeholder="Email..."
                        value={student[key].Email}
                        onChange={(e) => studentChange(e, "Email", key)}
                      />
                    )}
                  </InputContainer>
                );
              })}
              <IconContainer>
                <span>
                  <FiUserMinus size={30} onClick={minusStudent} />
                </span>
                <span onClick={addAnotherStudent}>
                  <LuUserPlus2 size={30} />
                </span>
              </IconContainer>
              <Button
                text="Create Students"
                onClick={saveStudents}
                floatRight
                mt={44}
              />
            </StepContainer>
          </>
        )}
        {!createNewStudent && (
          <>
            <StudentHeader>
              <Text size={24} mb={12}>
                All Students
              </Text>
              {school?.Slug !== "all" && (
                <DivComponent>
                  <Tooltip
                    anchorSelect="#create-student"
                    content="Create Student"
                  />
                  <Tooltip
                    anchorSelect="#student-csv"
                    content={`Download student csv for ${school?.Name}`}
                  />
                  <DivComponent ml={25} onClick={downloadCSV} id="student-csv">
                    <GrDocumentCsv size={25} />
                  </DivComponent>
                  <DivComponent
                    onClick={() => setCreateNewStudent(true)}
                    id="create-student"
                  >
                    <BsPersonPlus size={25} />
                  </DivComponent>
                </DivComponent>
              )}
            </StudentHeader>
            <GenericTable
              data={students}
              columns={dataColumnsStudents}
              idKey="UserID"
              withSearch
              pageSize={20}
            />
          </>
        )}
      </SectionContainerStudents>
    </>
  );
};
